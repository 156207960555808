import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useIntl } from 'gatsby-plugin-intl';
import { capitalizeFirstLetter } from '@utils/helpers';

import Text from '@components/text';
import { Link } from 'gatsby';
import BlockSanity from '@components/block-sanity';

const Wrapper = styled.div`
  ${tw`sm:mx-18 lg:p-0`}

  :nth-of-type(1) { ${tw`sm:ml-0`} }
  :nth-of-type(3) { ${tw`sm:mr-0`} }
  
  @media (min-width: 1024px) { max-width: 300px; }
  
  @media (min-width: 1280px) { max-width: 345px; }
`;

const Image = tw(Img)`w-full`;

const Title = tw(Text)`mt-15 mb-5 lg:mt-20 lg:mb-12`;

const Description = tw(Text)`opacity-63`;

const Post = ({ post }) => {
  const { locale } = useIntl();
  const isEng = locale === 'en';
  const pathBuilder = (slug) => {
    const langPart = isEng ? '/en/post/' : '/post/';
    return `${langPart}${slug}`;
  };

  return (
    <Wrapper>
      <Link to={pathBuilder(post[`slug_${locale}`].current)}>
        <Image fluid={post.mask_small.asset.fluid} />
        <Title fontFamily="bebasNeue" fontSizes={28} line-height={1.24} letterSpacing={35}>
          {post[`title_${locale}`]}
        </Title>
        <Description fontSizes={16} lineHeight={1.5} letterSpacing={20}>
          <BlockSanity content={post[`_rawPostPreview${capitalizeFirstLetter(locale)}`]} noSpacing />
        </Description>
      </Link>
    </Wrapper>
  );
};

Post.propTypes = {
  post: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.any,
    ]),
  ).isRequired,
};

export default Post;
