import React, { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import Post from '@components/homepage/blog/post';

const SwipeableViewsWrapper = styled.div`
  left: ${({ firstSlide }) => (firstSlide ? '-20px' : '0')};
  ${tw`relative transition-all duration-300`}
  
  & > div {
    ${tw`overflow-visible! px-30`}
  }
  
  .react-swipeable-view-container > div {
    ${tw`px-10 transition-all duration-300`}
    
    &:nth-of-type(1) {
      ${({ firstSlide }) => (firstSlide && tw`pl-0 pr-30`)};
    }
  }
`;

const Slide = tw.div`h-full`;

const Pagination = tw.div`flex justify-center items-center mt-25`;

const DotWrapper = tw.div`flex justify-center items-center w-20 h-12`;

const Dot = styled.div`
  ${tw`w-10 h-10 bg-brownDot opacity-50 rounded-full transition-all duration-300`}
  ${({ active }) => active && tw`opacity-100`}
`;

const Swipe = ({ posts }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  const handleClickedSlide = (e, index) => {
    e.preventDefault();
    setActiveSlide(index);
  };

  return (
    <>
      <SwipeableViewsWrapper firstSlide={activeSlide === 0}>
        <SwipeableViews
          index={activeSlide}
          onChangeIndex={setActiveSlide}
        >
          {posts.map((post, index) => (
            <Slide
              key={post.id}
              onClick={(e) => activeSlide !== index && handleClickedSlide(e, index)}
            >
              <Post post={post} />
            </Slide>
          ))}
        </SwipeableViews>
      </SwipeableViewsWrapper>
      <Pagination>
        {posts.map((post, index) => (
          <DotWrapper
            key={`${post.id}id`}
            onClick={() => activeSlide !== index && setActiveSlide(index)}
          >
            <Dot active={activeSlide === index} />
          </DotWrapper>
        ))}
      </Pagination>
    </>
  );
};

Swipe.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([
        PropTypes.any,
      ]),
    ),
  ).isRequired,
};

export default Swipe;
