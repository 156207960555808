import React from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { customContainer } from '@utils/twin-macro';
import { FormattedMessage } from 'gatsby-plugin-intl';

import Text from '@components/text';
import Posts from '@components/homepage/blog/posts';

const Wrapper = styled.div`
  background-image: linear-gradient(to bottom, #FFF 0, #FFF 200px, #DDECE9 200px, #DDECE9 100%);
  ${tw`relative`}

  @media (min-width: 1024px) {
    background-image: linear-gradient(to bottom, #FFF 0, #FFF 350px, #DDECE9 350px, #DDECE9 100%);
  }
`;

const Background = styled.div`
  top: 30px;
  left: -80px;
  right: -80px;
  height: 260px;
  transform: rotate(7deg);
  ${tw`absolute bg-backgroundGreenLight`}

  @media (min-width: 1024px) {
    top: 120px;
    height: 400px;
    transform: rotate(9deg);
  }

  @media (min-width: 1921px) {
    transform: rotate(7deg);
  }
`;

const Container = styled.div`
  ${customContainer}
  ${tw`relative w-320 pt-86 pb-40 sm:w-auto lg:pt-150 lg:pb-64`}
`;

const Hashtag = tw(Text)`ml-11 lg:ml-0`;

const Title = tw(Text)`ml-11 mb-35 lg:mt-8 lg:ml-0 lg:mb-36`;

const Index = ({ id }) => (
  <Wrapper>
    <Background />
    <Container>
      <Hashtag color="greenDark" fontSizes={16} lineHeight={1.4} letterSpacing={20} uppercase>
        <FormattedMessage id="homepage.blog.hashtag" />
      </Hashtag>
      <Title fontFamily="bebasNeue" fontSizes={[42, 58]} fontWeight={700} lineHeight={[1.38, 1]} letterSpacing={[52, 72]} uppercase>
        <FormattedMessage id="homepage.blog.title" />
      </Title>
      <Posts id={id} />
    </Container>
  </Wrapper>
);

Index.defaultProps = {
  id: '',
};

Index.propTypes = {
  id: PropTypes.string,
};

export default Index;
