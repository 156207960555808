import React from 'react';
import tw from 'twin.macro';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';

import Swipe from '@components/homepage/blog/swipe';
import Post from '@components/homepage/blog/post';

const MobileSwipe = tw.div`sm:hidden`;

const DesktopBlock = tw.div`hidden sm:block sm:px-11 sm:flex sm:justify-between lg:px-0`;

const Posts = ({ id }) => {
  const { allSanityPost } = useStaticQuery(graphql`
    query {
      allSanityPost(sort: {fields: date, order: DESC}, limit: 4) {
        nodes {
          id
          title_pl
          title_en
          mask_small {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          _rawPostPreviewPl
          _rawPostPreviewEn
          slug_en {
            current
          }
          slug_pl {
            current
          }
        }
      }
    }
  `);

  const posts = allSanityPost.nodes.filter((post) => post.id !== id).slice(0, 3);

  return (
    <>
      <MobileSwipe>
        <Swipe posts={posts} />
      </MobileSwipe>
      <DesktopBlock>
        {posts.map((post) => (
          <Post key={post.id} post={post} />
        ))}
      </DesktopBlock>
    </>
  );
};

Posts.defaultProps = {
  id: '',
};

Posts.propTypes = {
  id: PropTypes.string,
};

export default Posts;
